<template>
  <div style="">
    <div class="appBar">

      <img @click="routeBack('/', false)"
           style="height: 18px; width: 18px; margin-left: 18px;"
           src="https://gcdn.bionluk.com/site/icon/tansel_mobile_black_back.svg">

      <p class="category-header-title">{{title}}</p>
      <div style="height: 18px; width: 18px;"></div>
    </div>
    <div style="margin: 0 auto; padding-top: 50px;"></div>

    <div class="explore-marketplace-gig-container" :style="index%2 ===0 ? 'background-color: #ffffff;' : 'background-color: #f4f5f7;'"
         v-for="(gigList,index) in gigLists">
      <h1 style="margin-top: 20px; margin-bottom: 16px; font-size: 26px;">{{gigList.title}}</h1>
      <div v-html="gigList.description" style="font-size:15px; font-weight: 400; color: #2d3640; opacity: 0.8;"></div>
      <div class="profile-gigs-container">

        <single-gig :impression-u-u-i-d="impressionUUID"  v-for="(gig,index) in gigList.gigs" :item="gig" :key="index" style="margin-right: 25px;" />

      </div>
      <button class="super-button get-started-button" v-if="!user.uuid" @click="$router.push('/register')">Hemen başla</button>
    </div>
    <div class="hiddendiv"></div>
  </div>
</template>

<script>

import singleGig from "@/pages/components/singleGig";
  export default {
    name: "src-pages-body-marketplace-goLanding-v1_mobile",
    components: {singleGig},
    data() {
      return {
        gigLists: null,
        title:null,
        impressionUUID:null,
      }
    },

    methods: {
      getGoPage() {
        let slug = this.$store.state.routerParams[0];
        this.api.content.getGoPage(slug)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

              let trackGigObj = [];
              result.data.gig_lists.forEach((gigl) => {
                gigl.gigs.forEach((gig, gigdex) => {
                  trackGigObj.push({
                    gig_id:gig.id,
                    seller_id:gig.seller_id,
                    order_id: gigdex + 1
                  })
                })
              });
              let trackingProps = {
                page:'landingpagego',
                placement:result.data.slug,
                info:trackGigObj,
                title: this.impressionUUID
              };
              this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);

              this.gigLists = result.data.gig_lists;
              this.title = result.data.title;
              this.Helper.trackEvents.pageView(this.Helper.goLandingPageProps(result.data.id, result.data.slug, result.data.title));
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          }).catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },

      updateLikeStatusForGig(receivedGig) {
        this.api.general.updateLikeStatus(this.Constants.FAV_TYPES.GIG, receivedGig.likeStatus.action, receivedGig.uuid,null,this.$store.state.componentMap.page)
          .then(({data}) => {
            let result = data;

            if (result.success) {
            } else {
              this.$router.push(result.data.redirect_url);
              this.revertLikeStatus(receivedGig);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.revertLikeStatus(receivedGig);
          });
        this.reverseLikeStatus(receivedGig);
      },

      revertLikeStatus(receivedGig) {
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: this.imageUrls.likeIcon.text
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: this.imageUrls.likedIcon.text
          };
        }
      },

      reverseLikeStatus(receivedGig) {
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: this.imageUrls.likedIcon.text
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: this.imageUrls.likeIcon.text
          };
        }
      },

    },

    watch: {

    },

    created() {
      this.impressionUUID = this.Helper.generateUUID()
      this.getGoPage();
    }
  }

</script>

<style scoped lang="scss">
  .appBar {
    z-index: 99;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .explore-marketplace-gig-container {
    padding: 20px 15px 20px;
    min-width: calc(100vw - 30px);
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

  .explore-marketplace-gig-container h1 {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: #2d3640;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .profile-gigs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100vw - 30px);
    overflow-y: scroll;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .profile-gig-body {
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }

    display: inline-block;
    width: 240px;
    height: 296px;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    vertical-align: top;
    text-align: left;
  }

  .profile-gig-userinfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    margin-top: 10px;
  }

  .get-started-button {
    border-radius: 2px;
    background-image: linear-gradient(45deg, rgb(230, 50, 98), rgb(253, 64, 86));
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    padding-bottom: 3px;
    width: 220px;
    margin: 10px auto 10px;
    height: 50px;
  }
  .get-started-button:hover {


    background-image: linear-gradient(45deg, #ee2344, #ee2344);

  }

</style>
